.Testimonials{
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	margin-bottom: min(30px, calc((30/390) * 100vw));
	text-align: center;
}
.Testimonials .SectionTitle{
	margin-bottom: min(38px, calc((23/390) * 100vw));
}
.TestimonialsSlider{
	display: flex;
	justify-content: start;
	align-items: center;
	width: min(622px, calc((362/390) * 100vw));
	overflow: hidden;
	border: min(1px, calc((1/390) * 100vw)) solid #005A8C;
	border-radius: min(25px, calc((25/390) * 100vw));
}
.Slide{
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	border-radius: min(25px, calc((25/390) * 100vw));
	background: white;
	padding: min(30px, calc((25/390) * 100vw)) min(35px, calc((20/390) * 100vw)) min(50px, calc((35/390) * 100vw)) min(35px, calc((20/390) * 100vw));
	font-size: min(18px, calc((15/390) * 100vw));
	font-weight: 400;
}
.SlideTitle{
	font-size: min(21px, calc((17/390) * 100vw));
	font-weight: 600;
	margin-bottom: min(16px, calc((12/390) * 100vw));
}
.SlideTop{
	margin-bottom: min(26px, calc((18/390) * 100vw));
	display: flex;
	justify-content: space-between;
	align-items: start;
	direction: ltr;
	width: min(550px, calc((320/390) * 100vw));
}
.StarsIcons{
	background-image: url("../../Assets/Stars.svg");
	width: min(124.96px, calc((96.56/390) * 100vw));
	height: min(22px, calc((17/390) * 100vw));
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}
.SlideBody{
	width: min(550px, calc((320/390) * 100vw));
}
.CirclesContainer{
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: min(12px, calc((12/390) * 100vw));
}
.Circle{
	width: min(15px, calc((15/390) * 100vw));
	height: min(15px, calc((15/390) * 100vw));
	border: min(1px, calc((1/390) * 100vw)) solid #005A8C;
	border-radius: 100%;
	margin: 0px min(4px, calc((4/390) * 100vw));
	background: white;
	cursor: pointer;
}
.ActiveCircle{
	background: #005A8C !important;
}
.TestimonialsContainer{
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.LeftBtn{
	background-image: url("../../Assets/PrevBtn.svg");
	width: min(32px, calc((25/390) * 100vw));
	height: min(32px, calc((25/390) * 100vw));
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	cursor: pointer;
}
.RightBtn{
	background-image: url("../../Assets/NexBtn.svg");
	width: min(32px, calc((25/390) * 100vw));
	height: min(32px, calc((25/390) * 100vw));
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	cursor: pointer;
}