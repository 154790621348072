.UploadSection{
	width: min(750px, calc((360/390) * 100vw));
	margin-bottom: min(40px, calc((30/390) * 100vw));
}

.BlueMessage{
	font-size: min(16px, calc((14/390) * 100vw));
	font-weight: 500;
	color: #005A8C;
	margin-top: min(8px, calc((15/390) * 100vw));
}