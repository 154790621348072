.ListItem{
	display: flex;
	justify-content: start;
	align-items: start;
	font-size: min(17px, calc((17/390) * 100vw));
	font-weight: 400;
	line-height: 160%;
	margin-bottom: min(15px, calc((15/390) * 100vw));
	text-align: start;
}
.ListBulletBlue{
	background: #005A8C;
	display: block;
	border-radius: 100%;
	width: min(5px, calc((5/390) * 100vw));
	height: min(5px, calc((5/390) * 100vw));
	margin: min(9.5px, calc((9.5/390) * 100vw)) min(10px, calc((10/390) * 100vw));
}
.ListBulletBlack{
	background: black;
	display: block;
	border-radius: 100%;
	width: min(5px, calc((5/390) * 100vw));
	height: min(5px, calc((5/390) * 100vw));
	margin: min(9.5px, calc((9.5/390) * 100vw)) min(10px, calc((10/390) * 100vw));
}
