button.SecondaryBtn{
	padding: 0px;
}

.SecondaryBtn {
	width: calc(100vw * 124.44/390);
	height: calc(100vw * 35/390);
	max-width: 140px;
	max-height: 45px;
	border-radius: 7px;
	font-size: min(16px, calc((14/390) * 100vw));
	font-weight: 600;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.SecondaryBtn:hover {
	font-size: min(15px, calc((13/390) * 100vw));
}