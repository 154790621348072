.InvoiceSignature{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin: min(60px, calc((40/390) * 100vw)) auto min(80px, calc((60/390) * 100vw)) auto;
	font-size: min(20px, calc((16/390) * 100vw));
	font-weight: 600;
	color: #005A8C;
}
.InvoiceSignature > img{
	margin-bottom:  min(15px, calc((8/390) * 100vw));
	width: min(50px, calc((40/390) * 100vw));
	height: min(50px, calc((40/390) * 100vw));
	border-radius: 100%;
	border: min(1px, calc((1/390) * 100vw)) solid #005A8C;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}
