.InvoicePageTitleContainer{
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: min(40px, calc((33/390) * 100vw));
}
.InvoiceOwnerPicTop{
	margin: 0px min(7.5px, calc((7.5/390) * 100vw));
	width: min(50px, calc((40/390) * 100vw));
	height: min(50px, calc((40/390) * 100vw));
	border-radius: 100%;
	border: min(1px, calc((1/390) * 100vw)) solid #005A8C;
	
}
.InvoicePageTitle{
	margin: 0px min(7.5px, calc((7.5/390) * 100vw));
	font-size: min(27px, calc((23/390) * 100vw));
	font-weight: 500;
}