.CreateInfoForm{
	width: min(750px, calc((360/390) * 100vw));
	margin: 0px auto min(35px, calc((25/390) * 100vw)) auto;
}
.CreateInfoForm > .FormTitle{
	font-size: min(16px, calc((14/390) * 100vw));
	font-weight: 600;
	margin-bottom:  min(12px, calc((10/390) * 100vw));
	color: #006071;
}
.CreateInfoFormInput{
	display: flex;
	justify-content: center;
	align-items: center;
	width:  min(750px, calc((360/390) * 100vw));
}
.CreateInfoFormInputIcon{
	width: min(37px, calc((37/390) * 100vw));
}
.CreateInfoFormInputIcon:hover{
	width: min(34px, calc((34/390) * 100vw));
}

.CreateInfoFormInput .AppInputContainer .AppInput{
	width: min(663px, calc((283/390) * 100vw));
}
/* .CreateInfoFormInput .AppInputContainer{
	background: #ffdbdb;
} */
.CreateInputSubmit{
	margin-top: min(10px, calc((7/390) * 100vw));
}