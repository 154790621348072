.AccountSettings{
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: min(35px, calc((25/390) * 100vw));
	padding-bottom: min(80px, calc((50/390) * 100vw));
}
.AdjustWidth{
	width: min(750px, calc((360/390) * 100vw));
	margin: 0px auto;
}
.UploadProfilePic{
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: min(292px, calc((230/390) * 100vw));
	margin-top: min(25px, calc((20/390) * 100vw)) ;
	margin-bottom: min(30px, calc((25/390) * 100vw));
}
.ProfilePic{
	width: min(75px, calc((55/390) * 100vw));
	height: min(75px, calc((55/390) * 100vw));
	border-radius: 100%;
	border: solid 1px #005A8C;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}

.InfoFormsContainer{
	margin-bottom: min(50px, calc((40/390) * 100vw));
}
