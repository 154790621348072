.FavPlan, .Plan{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: min(285px, calc((265/390) * 100vw));
	padding: min(35px, calc((35/390) * 100vw)) min(18px, calc((18/390) * 100vw)) min(30px, calc((30/390) * 100vw)) min(18px, calc((18/390) * 100vw));
	background: white;
	border-radius: min(15px, calc((15/390) * 100vw));
	border: solid #005A8C min(1px, calc((1/390) * 100vw));
	margin: min(15px, calc((15/390) * 100vw));
}	
.FavPlan{
	padding: min(22px, calc((22/390) * 100vw)) min(18px, calc((18/390) * 100vw)) min(30px, calc((30/390) * 100vw)) min(18px, calc((18/390) * 100vw));
}
.PlanTitle{
	font-size: min(24px, calc((20/390) * 100vw));
	font-weight: 500;
	color: #005A8C;
}
.PlanPrice{
	margin-top: min(10px, calc((10/390) * 100vw));
	font-size: min(24px, calc((20/390) * 100vw));
	font-weight: 600;
	color: #005A8C;
}
.PlanOldPrice{
	font-size: min(16px, calc((16/390) * 100vw));
	text-decoration: line-through;
	color: #6B6767;
}
.FavStars{
	background-image: url("../../Assets/FavStars.svg");
	width: min(83px, calc((61.48/390) * 100vw));
	height: min(27px, calc((20/390) * 100vw));
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	margin-bottom: min(14px, calc((12/390) * 100vw));
}
.PlanList{
	margin: min(34px, calc((22/390) * 100vw)) 0px min(26px, calc((26/390) * 100vw)) 0px;
}