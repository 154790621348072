.CopyInputContainer{
	width: 100%;
	margin-bottom: min(15px, calc((10/390) * 100vw));
}
.AppLabel{
	font-size: min(18px, calc((16/390) * 100vw));
	font-weight: 400;
	margin-bottom: min(4px, calc((4/390) * 100vw));
	margin-right: min(10px, calc((5/390) * 100vw));
	margin-left: min(10px, calc((5/390) * 100vw));
}
.CopyInput{
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.CopyInput > input{
	width: min(582px, calc((225/390) * 100vw));
	height: min(50px, calc((50/390) * 100vw));
	border-radius: min(5px, calc((5/390) * 100vw));
	border: 0px;
	border-bottom: solid #006071 min(1px, calc((1/390) * 100vw));
	font-size: min(18px, calc((16/390) * 100vw));
	font-weight: 500;
	padding: 0px min(10px, calc((5/390) * 100vw));
	color: #005A8C;
}
.CopyInput > input:focus{
	outline: 0px;
}
.CopyBtn{
	display: flex;
	justify-content: center;
	align-items: center;
	width: min(55px, calc((55/390) * 100vw));
	height: min(50px, calc((50/390) * 100vw));
	background: white;
	color: #005A8C;
	border: solid min(1px, calc((1/390) * 100vw)) #005A8C;
	border-radius: min(10px, calc((10/390) * 100vw));
	font-size: min(15px, calc((13/390) * 100vw));
	font-weight: 500;
	cursor: pointer;
}
.CopiedAlertContainer{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.CopiedAlert{
	width: min(55px, calc((55/390) * 100vw));
	margin-top: min(5px, calc((3/390) * 100vw));
	font-size: min(14px, calc((12/390) * 100vw));
	color: #005A8C;
	text-align: center;
}