button.PrimaryBtn{
	padding: 0px;
}
.PrimaryBtn {
	width: min(200px, calc((160/390) * 100vw));
	height: min(50px, calc((40/390) * 100vw));
	font-size: min(18px, calc((16/390) * 100vw));
	border-radius: min(7px, calc((7/390) * 100vw));
	font-weight: 600;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.PrimaryBtn:hover {
	font-size: min(17px, calc((15/390) * 100vw));
}

.BlueBtn {
	color: white;
	border: solid min(1px, calc((1/390) * 100vw)) #005A8C;
	background: #005A8C;
}

.WhiteBtn {
	color: #005A8C;
	border: solid min(1px, calc((1/390) * 100vw)) #005A8C;
	background: white;
}

.BlueBorderBtn {
	color: #005A8C;
	border: solid min(1px, calc((1/390) * 100vw)) #005A8C;
	background: none;
}

.WhiteBorderBtn {
	color: white;
	border: solid min(1px, calc((1/390) * 100vw)) white;
	background: none;
}

.RedBtn {
	color: white;
	border: solid min(1px, calc((1/390) * 100vw)) #EC4747;
	background: #EC4747;
}

.RedBorderBtn {
	color: #EC4747;
	border: solid min(1px, calc((1/390) * 100vw)) #EC4747;
	background: none;
}

.LightBlueBtn {
	color: white;
	border: solid min(1px, calc((1/390) * 100vw)) #3498DB;
	background: #3498DB;
}

.OrangeBtn {
	color: white;
	border: solid min(1px, calc((1/390) * 100vw)) #F39C12;
	background: #F39C12;
}

.GreenBtn {
	color: white;
	border: solid min(1px, calc((1/390) * 100vw)) #2ECC71;
	background: #2ECC71;
	cursor: default;
}

.DisabledBtn {
	opacity: 0.7;
}

.PrimaryBtnIcon {
	width: min(26px, calc((21/390) * 100vw));
	height: min(26px, calc((21/390) * 100vw));
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	color: #005A8C;
}

.PrimaryBtn>* {
	margin: 0px min(3px, calc((3/390) * 100vw));
}

.TwoBtns {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: min(422px, calc((330/390) * 100vw));
	margin: 0px auto;
}

.DeleteBtnContainer {
	margin-top: min(20px, calc((15/390) * 100vw));
}