.ToggleContainer{
	width: calc(100vw * 77.33/390);
	height: calc(100vw * 29/390);
	max-height: 36.75px;
	max-width: 98px;
	border: 1px #014165 solid;
	border-radius: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	direction: ltr;
}
.Toggle{
	width: calc(100vw * 34.8/390);
	height: calc(100vw * 22.56/390);
	max-width: 44.1px;
	max-height: 28.58px;
	color: #014165;
	border-radius: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 600;
	font-size: 20px;
	font-family: Arial, Helvetica, sans-serif;
}
.ActiveToggle{
	background-color: #014165;;
	color: white;
}

@media (max-width: 560px) {
	.Toggle{
		font-size: calc(100vw * 14/390);
	}
}