/* Invoice details section */
.InvoiceDetailsEditorSection {
  margin: min(40px, calc((24/390) * 100vw)) auto min(22px, calc((22/390) * 100vw)) auto;
  width: min(750px, calc((360/390) * 100vw));
}

/* Top section of invoice details */
.InvoiceDetailsEditorTop {
  display: flex;
  justify-content: space-between;
  align-items: start;
  background: #014165;
  color: white;
  padding: min(5px, calc((5/390) * 100vw)) min(5px, calc((5/390) * 100vw));
  border-radius: min(5px, calc((5/390) * 100vw)) min(5px, calc((5/390) * 100vw)) 0px 0px;
  border: solid #005A8C min(1px, calc((1/390) * 100vw));
}

/* Input fields in invoice details */
.InvoiceDetailsEditorTop input,
.InvoiceDetailsEditorTop textarea,
.InvoiceDetailsEditorTop select {
  font-size: min(22px, calc((17/390) * 100vw));
  font-weight: 400;
  border: 0px;
  border-radius: min(5px, calc((5/390) * 100vw));
  background: none;
  color: white;
}

/* Placeholder text color for input and textarea */
.InvoiceDetailsEditorTop input::placeholder,
.InvoiceDetailsEditorTop textarea::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

/* TextareaWithAutoResize */
.InvoiceDetailsEditorTop .grow-wrap>textarea,
.InvoiceDetailsEditorTop .grow-wrap::after {
  padding: min(10px, calc((7/390) * 100vw)) min(10px, calc((8/390) * 100vw));
  width: min(500px, calc((210/390) * 100vw));
  line-height: min(30.8px, calc((23.8/390) * 100vw));
  /*line height 140%*/
  font-size: min(22px, calc((17/390) * 100vw));
}

/* Input field for invoice price */
.InvoiceEditorPrice>input {
  height: min(50px, calc((36/390) * 100vw));
  width: min(70px, calc((50/390) * 100vw));
  padding: min(0px, calc((0/390) * 100vw)) min(5px, calc((2/390) * 100vw));
  direction: ltr;

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
}

/* Styling for select element in invoice details */
.InvoiceDetailsEditorTop select {
  width: min(80px, calc((60/390) * 100vw));
  padding: min(0px, calc((0/390) * 100vw)) min(5px, calc((2/390) * 100vw));
  height: min(52px, calc((38/390) * 100vw));
  line-height: min(52px, calc((38/390) * 100vw));
}

/* Invoice price section */
.InvoiceEditorPrice {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Styling for the number in invoice price section */
.InvoiceEditorPriceNumber {
  margin: 0px min(5px, calc((2/390) * 100vw));
}



/* Invoice details container */
.InvoiceDetailsEditorList .grow-wrap>textarea,
.InvoiceDetailsEditorList .grow-wrap::after,
.InvoiceDetailsEditorList .DetailsList {
  background: white;
  color: black;
  border: solid #005A8C min(1px, calc((1/390) * 100vw));
  margin: 0px;
  padding: min(15px, calc((12/390) * 100vw)) min(25px, calc((20/390) * 100vw)) min(22px, calc((18/390) * 100vw)) min(25px, calc((20/390) * 100vw));
  border-radius: 0px 0px min(5px, calc((5/390) * 100vw)) min(5px, calc((5/390) * 100vw));
  font-size: min(17px, calc((17/390) * 100vw));
  font-weight: 400;
  line-height: min(34px, calc((34/390) * 100vw));
  text-align: start;
}
.InvoiceDetailsEditorList .DetailsList{
  padding: min(22px, calc((18/390) * 100vw)) min(15px, calc((12/390) * 100vw));
}
