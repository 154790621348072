.AppInputContainer{
	width: 100%;
	margin-bottom: min(15px, calc((10/390) * 100vw));
}
.AppLabel{
	font-size: min(18px, calc((16/390) * 100vw));
	font-weight: 400;
	margin-bottom: min(4px, calc((4/390) * 100vw));
	margin-right: min(10px, calc((5/390) * 100vw));
	margin-left: min(10px, calc((5/390) * 100vw));
}
.AppInput{
	width: min(700px, calc((320/390) * 100vw));
	height: min(50px, calc((50/390) * 100vw));
	border-radius: min(5px, calc((5/390) * 100vw));
	border: 0px;
	border-bottom: solid #006071 min(1px, calc((1/390) * 100vw));
	font-size: min(18px, calc((16/390) * 100vw));
	font-weight: 500;
	padding: 0px min(25px, calc((20/390) * 100vw));
}
.AppInput:focus{
	outline: 0px;
}