.Header{
	padding: min(15px, calc((15/390) * 100vw)) 0px;
	border-bottom: solid 0.5px #6B6767;
	background: white;
	display: flex;
	justify-content: center;
	align-items: center;
}
.HeaderContainer{
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 90%;
}
.HeaderLogoAR{
	background-image: url("../../Assets/DafatirAR.svg");
	width: calc(100vw * 86.34/390);
	height: calc(100vw * 35/390);
	max-width: 111.01px;
	max-height: 45px;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	cursor: pointer;
}
.HeaderLogoEN{
	background-image: url("../../Assets/DafatirEN.svg");
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	width: calc(100vw * 86.34/390);
	height: calc(100vw * 35/390);
	max-width: 111.01px;
	max-height: 45px;
	cursor: pointer;
}
.HeaderActions{
	width: calc(100vw * 212/390);
	max-width: 263px;
	display: flex;
	/* justify-content: space-between; */
	justify-content: flex-end;
	align-items: center;
}

