.Loading{
	text-align: center;
	margin: 0px auto;
}
.LoadingContainer {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 0px;
  background: #E7F1FF;
}
@keyframes ZoomIn {
  from {
    opacity: 0;
    transform: scale(0.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes ZoomOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.5);
  }
}

.Loading {
  animation: ZoomIn 0.3s forwards; /* Enter animation */
}

.Loading.leave {
  animation: ZoomOut 0.3s forwards; /* Leave animation */
}


