.MiniCard{
	width: min(150px, calc((118/390) * 100vw));
	height: min(160px, calc((126/390) * 100vw));
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: start;
	font-size: min(18px, calc((14/390) * 100vw));
	font-weight: 500;
	border-radius: min(15px, calc((15/390) * 100vw));
	border: solid min(1px, calc((1/390) * 100vw)) #005A8C;
	background: white;
	color: #005A8C;
	direction: rtl;
	cursor: pointer;
}
.MiniCardContent{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: min(150px, calc((118/390) * 100vw));
	height: min(160px, calc((126/390) * 100vw));
}
.MiniCardIcon{
	width: min(44px, calc((34/390) * 100vw));
	height: min(44px, calc((34/390) * 100vw));
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	color: #005A8C;
	margin-bottom: min(14px, calc((12/390) * 100vw));
}
.MiniCardNotify{
	position: absolute;
	align-self: flex-end;
	margin-top: min(10px, calc((10/390) * 100vw));
	margin-left: min(10px, calc((10/390) * 100vw));
	border-radius: 100%;
	width: min(30px, calc((22.5/390) * 100vw));
	height: min(30px, calc((22.5/390) * 100vw));
}
.NotifyRed{
	background-image: url("../../Assets/NotifyRed.svg");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}
.NotifyYellow{
	background-image: url("../../Assets/NotifyYellow.svg");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}
.MiniCardsSectionBg{
	width: 100%;
	background: #89B3DB;
	padding: min(18px, calc((20/390) * 100vw)) 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: min(50px, calc((30/390) * 100vw));
}
.MiniCardsSection{
	width: min(550px, calc((374/390) * 100vw));
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.MiniCardsSection > *{
	cursor: default;

}