.AdminDashboard{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: min(35px, calc((25/390) * 100vw));
	padding-bottom: min(60px, calc((45/390) * 100vw));
}
.DashboardMiniCards{
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: min(350px, calc((253.5/390) * 100vw));
	text-align: center;
	direction: ltr;
}
.AdminDashboard .SectionTitle{
	color: #005A8C;
	margin-top: min(40px, calc((30/390) * 100vw));
	margin-bottom: min(30px, calc((23/390) * 100vw));
}
.InvoiceRecord{
	display: flex;
	justify-content: space-between;
	align-items: start;
	width: min(750px, calc((360/390) * 100vw));
	padding: min(8px, calc((5/390) * 100vw)) min(8px, calc((5/390) * 100vw));
	font-size: min(18px, calc((16/390) * 100vw));
	font-weight: 500;
	border-radius: min(10px, calc((10/390) * 100vw));
	border: #005A8C solid min(1px, calc((1/390) * 100vw));
	background: white;
	margin-bottom: min(12px, calc((8/390) * 100vw)) ;
	cursor: pointer;
}
.RecordTitle{
	display: flex;
	justify-content: start;
	align-items: center;
	width: min(450px, calc((155/390) * 100vw));
	min-height: min(45px, calc((35/390) * 100vw));
	padding: 0px min(6px, calc((4/390) * 100vw));
	line-height: 160%;
}
.InvoiceStatusContainer{
	display: flex;
	justify-content: flex-end;
	align-items: start;
}
.RecordPrice{
	display: flex;
	justify-content: end;
	align-items: center;
	width: min(115px, calc((85/390) * 100vw));
	height: min(45px, calc((35/390) * 100vw));
	padding: 0px min(20px, calc((10/390) * 100vw));
}
.RecordStatus{
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	font-size: min(15px, calc((14/390) * 100vw));
	width: min(120px, calc((85/390) * 100vw));
	height: min(45px, calc((35/390) * 100vw));
	border-radius: min(7px, calc((7/390) * 100vw));
}
.sent{
	background: #3498DB;
}
.onReview{
	background: #F39C12;
}
.approved{
	background: #2ECC71;
}
