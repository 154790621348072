.NoticePage{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin: min(40px, calc((30/390) * 100vw)) auto 0px auto;
	width: min(650px, calc((330/390) * 100vw));
}
.NoticeTitle{
	font-size: min(27px, calc((22/390) * 100vw));
	font-weight: 500;
	margin-bottom:  min(15px, calc((10/390) * 100vw));
	color: #005A8C;
}
.NoticeBody{
	font-size: min(20px, calc((17/390) * 100vw));
	font-weight: 400;
	margin-bottom:  min(35px, calc((20/390) * 100vw));
}
.NoticePage button{

}