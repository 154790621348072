.CountdownContainer{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: calc(100vw * 248/390);
	max-width: 325px;
	text-align: center;
	margin: 0px auto;
	color: #014165;
}
.CountdownTitle{
	font-size: min(20px, calc((18/390) * 100vw));
	font-weight: 600;
	margin-bottom: min(15px, calc((9/390) * 100vw));
}
.CountdownSectionsContainer{
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
}
.CountdownSection{
	width: calc(100vw * 50/390);
	max-width: 70px;
	font-size: min(17px, calc((12/390) * 100vw));
	font-weight: 400;
	display: flex;
	flex-direction: column;
}
.CounterDivider, .CountdownNumber{
	font-weight: 700;
	font-size: min(32px, calc((27/390) * 100vw));
	color: #014165;
	margin-bottom: min(8px, calc((8/390) * 100vw));
}
