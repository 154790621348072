.EmailListContainer {
	background-color: #003857;
	padding: min(50px, calc((25/390) * 100vw)) 0px min(40px, calc((30/390) * 100vw)) 0px;
	width: 100%;
	font-size: min(23px, calc((16/390) * 100vw));
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: min(100px, calc((50/390) * 100vw)) auto 0px auto;
	color: white;
	text-align: center;
}

.EmailListContainer>input {
	width: min(380px, calc((240/390) * 100vw));;
	height: min(50px, calc((40/390) * 100vw));
	border: min(1px, calc((1/390) * 100vw)) solid #003857;
	border-radius: min(8px, calc((8/390) * 100vw));;
	text-align: center;
	font-size: min(18px, calc((14/390) * 100vw));
	font-weight: 400;
	margin-bottom: min(14px, calc((10/390) * 100vw));
	margin-top: min(17px, calc((17/390) * 100vw));;
}

.FooterCopyWrite {
	font-size: min(18px, calc((14/390) * 100vw));
	color: white;
	font-weight: 400;
	margin-top: min(60px, calc((45/390) * 100vw));
	width: 80vw;
}