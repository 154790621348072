.PricingPage{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: min(40px, calc((30/390) * 100vw));
}
.PlansContainer{
	margin-top: min(25px, calc((15/390) * 100vw));
	margin-bottom: min(35px, calc((35/390) * 100vw));
}

@media (min-width: 800px) {
	.PlansContainer{
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: end;
	}
}