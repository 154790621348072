.AlertBg {
	position: absolute;
	width: 100%;
	height: 100vh;
	background: rgb(0, 0, 0, 0.3);
}

.AlertBg.Leave{
	background: none;
	z-index: -10;
}
.AlertContainer{
	position: absolute;
	display: flex;
	justify-content: center;
	width: 100vw;
}
.Alert {
	z-index: 99;
	display: flex;
	justify-content: center;
	align-items: center;
	background: white;
	width: min(457px, calc((360/390) * 100vw));
	border-radius: min(10px, calc((10/390) * 100vw));
	text-align: center;
	padding: min(30px, calc((25/390) * 100vw)) 0px min(35px, calc((25/390) * 100vw)) 0px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	border: solid min(1px, calc((1/390) * 100vw)) #005A8C;
	margin-top: -100%;
}

.Alert.Open {
	animation: 0.4s slideIn;
	margin-top: min(27px, calc((27/390) * 100vw));
}

.Alert.Leave {
	animation: 0.4s slideOut;
	margin-top: -100%;
}

@keyframes slideIn {
	from {
		margin-top: -100%;
	}

	to {
		margin-top: min(27px, calc((27/390) * 100vw));
	}
}

@keyframes slideOut {
	from {
		margin-top: min(27px, calc((27/390) * 100vw));
	}

	to {
		margin-top: -100%;
	}
}

.AlertTitle {
	font-size: min(20px, calc((16/390) * 100vw));
	color: #005A8C;
	font-weight: 500;
	margin-bottom: min(15px, calc((10/390) * 100vw));
}

.AlertBody {
	font-size: min(18px, calc((15/390) * 100vw));
	font-weight: 500;
	margin-bottom: min(40px, calc((34/390) * 100vw));
}