.InvoiceDetailsSection{
	margin: min(40px, calc((24/390) * 100vw)) auto min(22px, calc((22/390) * 100vw)) auto;
	width: min(750px, calc((360/390) * 100vw));
}
.InvoiceDetailsTop{
	display: flex;
	justify-content: space-between;
	align-items: start;
	background: #014165;
	color: white;
	padding: min(17px, calc((12/390) * 100vw)) min(25px, calc((15/390) * 100vw));
	border-radius: min(5px, calc((5/390) * 100vw)) min(5px, calc((5/390) * 100vw)) 0px 0px;
	border: solid #005A8C min(1px, calc((1/390) * 100vw));
}
.InvoiceDetails{
	background: white;
	color: black;
	border: solid #005A8C min(1px, calc((1/390) * 100vw));
	margin: 0px;
	padding: min(15px, calc((12/390) * 100vw));
	border-radius: 0px 0px min(5px, calc((5/390) * 100vw)) min(5px, calc((5/390) * 100vw));
}
.InvoiceDetailsTitle{
	font-size: min(22px, calc((17/390) * 100vw));
	font-weight: 400;
	width: min(530px, calc((215/390) * 100vw));
  line-height: min(30.8px, calc((23.8/390) * 100vw));
}
.InvoicePrice{
	display: flex;
	justify-content: flex-end;
	align-items: center;
	font-weight: 500;
	font-size: min(22px, calc((17/390) * 100vw));
	width: min(140px, calc((105/390) * 100vw));
	padding: min(0px, calc((0/390) * 100vw)) min(5px, calc((2/390) * 100vw));
	text-align: end;
}