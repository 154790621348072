.LandingPageContent {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.MessageImgAr{
	background-image: url("../Assets/MessageImgAr.png");
	width: calc(100vw * 216/390);
	height: calc(100vw * 71.88/390);
	max-width: 288.49px;
	max-height: 96px;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	margin-top: min(26px, calc((18/390) * 100vw));
	margin-bottom: min(20px, calc((5/390) * 100vw));
}
.MessageImgEn{
	background-image: url("../Assets/MessageImgEn.png");
	width: calc(100vw * 203.96/390);
	height: calc(100vw * 71.88/390);
	max-width: 272.4px;
	max-height: 96px;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	margin-top: min(26px, calc((18/390) * 100vw));
	margin-bottom: min(20px, calc((5/390) * 100vw));
}

.LandingSlogan,
.LandingSlogan>* {
	font-size: min(50px, calc((33/390) * 100vw));
	width: calc(100vw * 360/390);
	max-width: 650px;
	font-weight: 700;
	text-align: center;
	margin-bottom: min(23px, calc((11/390) * 100vw));;
}

.LandingDescription {
	width: calc(100vw * 360/390);
	max-width: 660px;
	font-size: min(22px, calc((16/390) * 100vw));
	margin-bottom: min(30px, calc((20/390) * 100vw));;
}
.LandingAppUiEn {
	background-image: url("../Assets/AppUIsEn.png");
	width: min(686px, calc((360/390) * 100vw));
	height: min(588.87px, calc((309.03/390) * 100vw));
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	margin-top: min(30px, calc((25/390) * 100vw));
	margin-bottom: min(50px, calc((30/390) * 100vw));
}
.LandingAppUiAr {
	background-image: url("../Assets/AppUIsAr.png");
	width: min(686px, calc((360/390) * 100vw));
	height: min(588.87px, calc((309.03/390) * 100vw));
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	margin-top: min(30px, calc((25/390) * 100vw));
	margin-bottom: min(50px, calc((30/390) * 100vw));
}