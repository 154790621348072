.PaymentForm{
	width: min(750px, calc((360/390) * 100vw));
	margin: 0px auto min(25px, calc((16/390) * 100vw)) auto;
}
.FormTitle{
	font-size: min(16px, calc((14/390) * 100vw));
	font-weight: 600;
	margin-bottom:  min(10px, calc((5/390) * 100vw));
	color: #006071;
}
.PaymentOptions{
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: min(18px, calc((14/390) * 100vw));
	font-weight: 600;
}
.PaymentOptions div{
	display: flex;
	align-items: center;
	justify-content: center;
	width: min(240px, calc((115.2/390) * 100vw));
	height: min(50px, calc((40/390) * 100vw));
	border-radius: min(10px, calc((10/390) * 100vw)) min(10px, calc((10/390) * 100vw)) 0px 0px;
	border: solid #005A8C min(1px, calc((1/390) * 100vw));
	border-bottom: 0px;
	background: white;
	color: #005A8C;
	cursor: pointer;
}
.PaymentOptions div.ActiveOption{
	background: #005A8C;
	color: white;
}
.PaymentOptions div.DisabledOption{
	opacity: 0.5;
	background: white;
	color: #6B6767;
	border-color: #6B6767;
	cursor: not-allowed;
}
.PaymentFormDetails{
	padding: min(25px, calc((17/390) * 100vw));
	padding-bottom: min(18px, calc((10/390) * 100vw));
	background: white;
	border: solid min(1px, calc((1/390) * 100vw)) #005A8C;
	border-radius: 0px 0px min(15px, calc((15/390) * 100vw)) min(15px, calc((15/390) * 100vw));
}
.PaymentFormTitle{
	margin: 0px 0px min(20px, calc((12/390) * 100vw)) 0px;
	font-size: min(18px, calc((14/390) * 100vw));
	font-weight: 600;
	color: #005A8C;
}

